body {
  margin: 0;
  font-family: ABCROM-Regular;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'ABCROM-Regular';
  src: url('https://get-together.app/ABCROM-Regular.otf') format("opentype");
}

@font-face {
  font-family: 'ABCROMCondensed-Regular';
  src: url('https://get-together.app/ABCROMCondensed-Regular.otf') format("opentype");
}

@font-face {
  font-family: 'ABCROMMono-Regular';
  src: url('https://get-together.app/ABCROMMono-Regular.otf') format("opentype");
}